/*!
 * Moonkake v5.0.0
 *
 * https://github.com/detectiveshelby/moonkake
 */

/* TYPO
 * -------------------------------------------------- */

/* COLORS
 * -------------------------------------------------- */

/* MIXINS
 * -------------------------------------------------- */

/* Common */

/* Font-face */

/* Grid */

/* Font */

/* FONT
 *
 * http://www.fontsquirrel.com/fontface/generator
 * http://onlinefontconverter.com
 * http://www.font2web.com (EOT)
 * -------------------------------------------------- */

/*
 * 100 = thin
 * 200 = extra-light
 * 300 = light
 * 400 = normal, book
 * 500 = medium
 * 600 = semi-bold
 * 700 = bold
 * 800 = heavy
 * 900 = black
 */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600,700|PT+Sans:400,700|Roboto:100,300,400,700,900&subset=cyrillic');

@font-face {
  font-family: 'Raleway';
  src: url('../font/raleway-light-webfont.eot');
  src: url('../font/raleway-light-webfont.eot?#iefix') format('embedded-opentype'), url('../font/raleway-light-webfont.woff2') format('woff2'), url('../font/raleway-light-webfont.woff') format('woff'), url('../font/raleway-light-webfont.ttf') format('truetype'), url('../font/raleway-light-webfont.svg#raleway-light-webfont') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/raleway-bold-webfont.eot');
  src: url('../font/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../font/raleway-bold-webfont.woff2') format('woff2'), url('../font/raleway-bold-webfont.woff') format('woff'), url('../font/raleway-bold-webfont.ttf') format('truetype'), url('../font/raleway-bold-webfont.svg#raleway-bold-webfont') format('svg');
  font-weight: 700;
  font-style: normal;
}

/* Config */

/* COMMON
 * -------------------------------------------------- */

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 10px;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  line-height: 1.4;
  font-size: 1.7rem;
  margin: 0;
  direction: ltr;
  text-align: left;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 100;
  color: #000000;
  background: #ffffff;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* CONTENT
 * -------------------------------------------------- */

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -moz-outline-style: none;
  cursor: pointer;
  -webkit-transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
       -o-transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
          transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
}

a:hover {
  text-decoration: none;
}

a:not([class]) {
  text-decoration: underline;
}

a:not([class]):hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 45px 0;
  font-family: 'Open Sans', Arial, sans-serif;
  color: #1681c4;
  text-rendering: optimizelegibility;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 700;
}

h1 {
  font-size: 4.8rem;
  color: #224471;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 700;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 3.5rem;
}

h4 {
  font-size: 3.5rem;
}

h5 {
  font-size: 3.5rem;
}

h6 {
  font-size: 3.5rem;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  text-decoration: none;
  color: inherit;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6,
ul + h1,
ul + h2,
ul + h3,
ul + h4,
ul + h5,
ul + h6,
ol + h1,
ol + h2,
ol + h3,
ol + h4,
ol + h5,
ol + h6,
dl + h1,
dl + h2,
dl + h3,
dl + h4,
dl + h5,
dl + h6,
table + h1,
table + h2,
table + h3,
table + h4,
table + h5,
table + h6 {
  margin-top: 45px;
}

p {
  margin: 0 0 20px 0;
}

ul,
ol {
  padding: 0;
  margin: 0 0 20px 25px;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0;
}

ul:not([class]) li + li,
ol:not([class]) li + li {
  margin-top: 10px;
}

dl {
  margin: 0 0 20px 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -50%;
}

sub {
  bottom: -25%;
}

big {
  font-size: 120%;
}

small {
  font-size: 80%;
}

tt,
pre,
kbd,
xmp,
code,
samp,
plaintext {
  font-family: monospace, sans-serif;
}

pre {
  overflow: auto;
}

address {
  font-style: normal;
  display: inline;
}

i,
em,
dfn,
cite {
  font-style: italic;
}

u,
ins,
var {
  text-decoration: underline;
}

b,
strong {
  font-weight: bold;
}

s,
del,
strike {
  text-decoration: line-through;
}

abbr,
acronym {
  cursor: help;
  border-bottom: 1px dashed #eeeeee;
}

mark {
  color: #000000;
  background: #ffff00;
}

q,
blockquote {
  quotes: '' '';
  font-style: normal;
  padding: 10px 20px;
  margin: 0 0 10px 0;
  border-left: 5px solid #eeeeee;
}

q *:last-child,
blockquote *:last-child {
  margin-bottom: 0;
}

q::after,
q::before,
blockquote::after,
blockquote::before {
  content: '';
}

img {
  vertical-align: top;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  height: auto;
  border: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #eeeeee;
  margin: 20px 0;
  padding: 0;
}

svg:not(:root) {
  overflow: hidden;
}

/* TABLE
 * -------------------------------------------------- */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: bold;
}

caption {
  text-align: center;
  font-weight: bold;
  padding-bottom: 10px;
}

/* HTML
 * -------------------------------------------------- */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

figure {
  margin: 0;
}

/* FORM
 * -------------------------------------------------- */

::-ms-clear {
  display: none;
}

iframe,
frame,
frameset,
fieldset,
form {
  padding: 0;
  margin: 0;
  border: 0;
}

label {
  display: block;
  vertical-align: middle;
}

legend {
  padding: 0 5px;
}

button,
input,
select,
optgroup,
textarea {
  font: inherit;
  color: inherit;
  margin: 0;
  vertical-align: baseline;
  outline: none;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
  height: auto;
}

*::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

*:-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

*::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}

*:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default;
}

input[type='checkbox'],
input[type='radio'] {
  vertical-align: baseline;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

input[type='search'],
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
  height: auto;
}

optgroup {
  font-weight: bold;
}

/* Libraries */

/* Magnific Popup CSS */

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor:         zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor:         zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #cccccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #cccccc;
}

.mfp-preloader a:hover {
  color: #ffffff;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #ffffff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

/* .mfp-close:active {
    top: 1px; } */

.mfp-close-btn-in .mfp-close {
  color: #333333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #ffffff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #cccccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #ffffff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #ffffff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000000;
}

/* Main image in popup */

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444444;
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-touch-callout: none;
   -khtml-user-select: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  /* height: 100%; */
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Sprites */

/* Common */

.footer {
  margin-top: 106px;
  padding-bottom: 45px;
  font-size: 1.5rem;
  overflow: hidden;
  position: relative;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 400;
}

.footer::before,
.footer::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  -webkit-transition: background-position 0.25s;
       -o-transition: background-position 0.25s;
          transition: background-position 0.25s;
}

.footer::before {
  background-image: url('../img/triangle-line-left.png');
  background-position: 0 0;
}

.footer::after {
  background-image: url('../img/triangle-line-right.png');
  background-position: 100% 0;
}

.footer-copyright {
  line-height: 1.2;
}

.footer .inner {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer .inner {
  margin-top: 0;
  margin-left: -40px;
}

.footer .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding-left: 40px;
  padding-top: 0;
}

.footer .contacts {
  font-family: inherit;
  font-weight: inherit;
  line-height: 1.2;
}

.footer .contacts-item + div {
  margin-top: 8px;
}

.footer .contacts-item a {
  text-decoration: none;
}

.footer .contacts-item--phone::before {
  background-image: url(../img/sprite.png);
  background-position: -388px -318px;
  width: 16px;
  height: 16px;
  top: 4px;
}

.footer .contacts-item--address::before {
  background-image: url(../img/sprite.png);
  background-position: -244px -152px;
  width: 12px;
  height: 18px;
  top: 5px;
}

.footer .contacts-item--email::before {
  background-image: url(../img/sprite.png);
  background-position: -370px -335px;
  width: 16px;
  height: 12px;
  top: 5px;
}

.header {
  height: 116px;
  background-color: #8ad6f1;
  position: relative;
  z-index: 10;
}

.header::after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  left: 50%;
  width: 50%;
  background-color: #224471;
  top: 0;
}

.header .container {
  position: relative;
  z-index: 1;
}

.header .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.header .inner > * {
  min-width: 0;
}

.header-logotype {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 250px;
      flex: 0 0 250px;
  max-width: 250px;
  background-color: #ffffff;
  text-align: center;
  padding: 10px;
}

.header .logotype {
  display: block;
  padding-bottom: 20px;
}

.header .logotype + .phone {
  margin-top: 20px;
}

.header .phone {
  color: #ffffff;
  padding: 4px 30px;
  font-size: 1.8rem;
  background: #4cc9ef;
  display: inline-block;
  position: relative;
  font-weight: 700;
}

.header .phone:before {
  content: '';
  display: block;
  position: absolute;
  background-image: url(../img/sprite.png);
  background-position: -371px -318px;
  width: 16px;
  height: 16px;
  left: 10px;
  top: 50%;
  margin-top: -8px;
}

.header-navigation {
  -webkit-box-flex: 1;
  -ms-flex: 1;
      flex: 1;
}

.navigation-toggler {
  display: none;
  width: 60px;
  height: 60px;
  border: 2px solid #ffffff;
  background: transparent;
  color: #ffffff;
  padding: 0;
  margin: 28px 0 0 auto;
  cursor: pointer;
  font-size: 5rem;
  line-height: 56px;
}

.navigation-primary {
  height: 116px;
}

.navigation-primary .list-item {
  height: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66%;
      flex: 0 0 16.66%;
  max-width: 16.66%;
  text-align: center;
}

.navigation-primary .list-item:hover .list-link,
.navigation-primary .list-item.-active .list-link {
  color: #ffd55a;
}

.navigation-primary .list-item .list-link {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.6rem;
  padding-bottom: 20px;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
      flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 600;
}

.navigation-primary .list-item .list-link > * {
  min-width: 0;
}

.navigation-primary .list-item .list-title {
  display: block;
}

.navigation-primary .list-item .list-icon {
  margin: 0 auto 13px auto;
}

/* GRID
 * -------------------------------------------------- */

/* Container */

.container {
  max-width: 1095px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

/* Columns */

.row::before,
.row::after {
  content: ' ';
  display: table;
}

.row::after {
  clear: both;
}

.row-table {
  display: table;
}

.row-table > * {
  display: table-cell;
  overflow: visible;
}

.col-left {
  float: left;
}

.col-right {
  float: right;
}

.col-middle {
  overflow: hidden;
}

/* Grid */

.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.grid > * {
  min-width: 0;
}

.grid > .col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.grid > .col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.grid > .col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
}

.grid > .col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.grid > .col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.grid > .col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
}

.grid > .col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.grid > .col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.grid > .col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%;
}

.grid > .col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.grid > .col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.grid > .col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%;
}

/* MISC
 * -------------------------------------------------- */

/* Text */

.text-strike {
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-align: center;
}

.text-strike::before,
.text-strike::after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #000000;
  z-index: -1;
  position: relative;
  vertical-align: middle;
}

.text-strike::before {
  right: 20px;
  margin: 0 0 0 -100%;
}

.text-strike::after {
  left: 20px;
  margin: 0 -100% 0 0;
}

.text-justify {
  text-align: justify;
  line-height: 0;
  font-size: 1px;
  text-justify: newspaper;
  zoom: 1;
  text-align-last: justify;
}

.text-justify::after {
  width: 100%;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  content: '';
  display: inline-block;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-color-primary {
  color: #1681c4;
}

.text-color-secondary {
  color: #f39c12;
}

/* Content */

img[style*='float: left'],
img[align='left'] {
  margin: 0 15px 15px 0;
  float: left;
}

img[style*='float: right'],
img[align='right'] {
  margin: 0 0 15px 15px;
  float: right;
}

/* BLOCKS
 * -------------------------------------------------- */

/* List */

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-link {
  display: block;
}

.list-inline > .list-item {
  display: inline-block;
  vertical-align: top;
}

.list-float::before,
.list-float::after {
  content: ' ';
  display: table;
}

.list-float::after {
  clear: both;
}

.list-float > .list-item {
  float: left;
}

.list-table {
  display: table;
  width: 100%;
}

.list-table > .list-item {
  display: table-cell;
  vertical-align: middle;
}

.list-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.list-flex > * {
  min-width: 0;
}

/* Form */

.form-group + .form-group {
  margin-top: 20px;
}

.form-row {
  margin-left: -10px;
}

.form-row::before,
.form-row::after {
  content: ' ';
  display: table;
}

.form-row::after {
  clear: both;
}

.form-row > .form-item {
  padding-left: 10px;
  float: left;
  width: 50%;
}

.form-label {
  display: block;
  color: #5b5b5b;
  font-size: 1.4rem;
  margin-bottom: 10px;
  line-height: 1;
  font-family: 'PT Sans', Arial, sans-serif;
  font-weight: 400;
}

.form-field {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 0;
  padding: 12px;
  color: #000000;
  -webkit-transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
       -o-transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
          transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
}

.form-field:focus {
  border-color: #224471;
}

.form textarea {
  height: 168px;
}

.form-submit {
  margin-top: 10px;
}

.form-error {
  color: red;
}

.select {
  position: relative;
  display: inline-block;
}

.select::after {
  content: '';
  display: block;
  position: absolute;
}

.select select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
}

.select select::-ms-expand {
  display: none;
}

.radio,
.checkbox {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.radio input,
.checkbox input {
  position: absolute;
  left: -999px;
  top: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.radio input:checked + label::after,
.checkbox input:checked + label::after {
  opacity: 1;
}

.radio label,
.checkbox label {
  display: block;
  line-height: 20px;
  padding-left: 30px;
  position: relative;
  font-size: 1.4rem;
}

.radio label::before,
.checkbox label::before {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background: #cccccc;
  top: 0;
  left: 0;
}

.radio label::after,
.checkbox label::after {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  background: #dddddd;
  left: 5px;
  top: 5px;
  opacity: 0;
}

.radio label::before,
.radio label::after {
  border-radius: 50%;
}

/* Tabs */

.tabs .tab-title::before,
.tabs .tab-title::after {
  content: ' ';
  display: table;
}

.tabs .tab-title::after {
  clear: both;
}

.tabs .tab-title .tab-item {
  float: left;
  cursor: pointer;
}

.tabs .tab-content .tab-item {
  display: none;
}

.tabs .tab-content .tab-item.-active {
  display: block;
}

/* Popup */

.popup {
  background: #ffffff;
  position: relative;
  padding: 20px;
  width: auto;
  max-width: 700px;
  margin: 0 auto;
}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
       -o-transition: all 0.15s ease-out;
          transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
       -o-transition: all 0.15s ease-out;
          transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/* Buttons */

.button {
  display: inline-block;
  text-decoration: none;
  padding: 9px;
  border: 2px solid #f39c12;
  border-radius: 0;
  background: transparent;
  color: #000000;
  text-transform: uppercase;
  min-width: 160px;
  text-align: center;
  font-size: 1.5rem;
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: 600;
  -webkit-transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
       -o-transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
          transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
}

.button:hover {
  border-color: #1681c4;
}

/* Breadcrumbs */

.breadcrumbs .list-link {
  display: inline-block;
}

/* Pagination */

/* Carousel */

.carousel .slick-arrow {
  position: absolute;
  top: 50%;
  font-size: 0;
  border: 0;
  background-color: transparent;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
}

.carousel .slick-prev {
  background-image: url(../img/sprite.png);
  background-position: -146px -144px;
  width: 29px;
  height: 50px;
  left: 20px;
}

.carousel .slick-next {
  background-image: url(../img/sprite.png);
  background-position: -146px -93px;
  width: 29px;
  height: 50px;
  right: 20px;
}

/* Icons */

.icon {
  display: inline-block;
}

.icon-home {
  background-image: url(../img/sprite.png);
  background-position: -353px -162px;
  width: 56px;
  height: 55px;
}

.icon-build {
  background-image: url(../img/sprite.png);
  background-position: -353px -270px;
  width: 51px;
  height: 47px;
}

.icon-services {
  background-image: url(../img/sprite.png);
  background-position: -358px -350px;
  width: 47px;
  height: 45px;
}

.icon-objects {
  background-image: url(../img/sprite.png);
  background-position: -180px -152px;
  width: 63px;
  height: 43px;
}

.icon-brands {
  background-image: url(../img/sprite.png);
  background-position: -353px -218px;
  width: 51px;
  height: 51px;
}

.icon-contacts {
  background-image: url(../img/sprite.png);
  background-position: -310px -350px;
  width: 47px;
  height: 46px;
}

.icon-building-1 {
  background-image: url(../img/sprite.png);
  background-position: -245px -198px;
  width: 68px;
  height: 66px;
}

.icon-building-2 {
  background-image: url(../img/sprite.png);
  background-position: 0 -350px;
  width: 72px;
  height: 61px;
}

.icon-building-3 {
  background-image: url(../img/sprite.png);
  background-position: -145px -350px;
  width: 86px;
  height: 48px;
}

.icon-building-4 {
  background-image: url(../img/sprite.png);
  background-position: -73px -350px;
  width: 71px;
  height: 59px;
}

.icon-building-5 {
  background-image: url(../img/sprite.png);
  background-position: -232px -350px;
  width: 77px;
  height: 46px;
}

.icon-building-6 {
  background-image: url(../img/sprite.png);
  background-position: 0 -198px;
  width: 82px;
  height: 72px;
}

.icon-building-7 {
  background-image: url(../img/sprite.png);
  background-position: -210px -271px;
  width: 65px;
  height: 73px;
}

.icon-object-1 {
  background-image: url(../img/sprite.png);
  background-position: -353px -81px;
  width: 60px;
  height: 80px;
}

.icon-object-2 {
  background-image: url(../img/sprite.png);
  background-position: 0 -271px;
  width: 69px;
  height: 78px;
}

.icon-object-3 {
  background-image: url(../img/sprite.png);
  background-position: 0 -93px;
  width: 72px;
  height: 104px;
}

.icon-object-4 {
  background-image: url(../img/sprite.png);
  background-position: -279px 0;
  width: 73px;
  height: 91px;
}

.icon-object-5 {
  background-image: url(../img/sprite.png);
  background-position: 0 0;
  width: 89px;
  height: 92px;
}

.icon-object-6 {
  background-image: url(../img/sprite.png);
  background-position: -180px -76px;
  width: 98px;
  height: 75px;
}

.icon-object-7 {
  background-image: url(../img/sprite.png);
  background-position: -140px -271px;
  width: 69px;
  height: 78px;
}

.icon-object-8 {
  background-image: url(../img/sprite.png);
  background-position: -164px -198px;
  width: 80px;
  height: 70px;
}

.-active .icon-object-1 {
  background-image: url(../img/sprite.png);
  background-position: -353px 0;
  width: 60px;
  height: 80px;
}

.-active .icon-object-2 {
  background-image: url(../img/sprite.png);
  background-position: -70px -271px;
  width: 69px;
  height: 78px;
}

.-active .icon-object-3 {
  background-image: url(../img/sprite.png);
  background-position: -73px -93px;
  width: 72px;
  height: 104px;
}

.-active .icon-object-4 {
  background-image: url(../img/sprite.png);
  background-position: -279px -92px;
  width: 71px;
  height: 89px;
}

.-active .icon-object-5 {
  background-image: url(../img/sprite.png);
  background-position: -90px 0;
  width: 89px;
  height: 92px;
}

.-active .icon-object-6 {
  background-image: url(../img/sprite.png);
  background-position: -180px 0;
  width: 98px;
  height: 75px;
}

.-active .icon-object-7 {
  background-image: url(../img/sprite.png);
  background-position: -276px -271px;
  width: 63px;
  height: 72px;
}

.-active .icon-object-8 {
  background-image: url(../img/sprite.png);
  background-position: -83px -198px;
  width: 80px;
  height: 70px;
}

/* Contacts */

.contacts {
  font-size: 1.5rem;
  display: inline-block;
  font-family: 'PT Sans', Arial, sans-serif;
}

.contacts-item + div {
  margin-top: 20px;
}

.contacts-item {
  position: relative;
}

.contacts-item a {
  color: inherit;
  text-decoration: underline;
}

.contacts-item--address,
.contacts-item--email,
.contacts-item--phone,
.contacts-item--time {
  padding-left: 30px;
}

.contacts-item::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.contacts-item--phone::before {
  background-image: url(../img/sprite.png);
  background-position: -279px -182px;
  width: 15px;
  height: 15px;
  top: 4px;
}

.contacts-item--address::before {
  background-image: url(../img/sprite.png);
  background-position: -353px -335px;
  width: 16px;
  height: 14px;
  top: 4px;
}

.contacts-item--email::before {
  background-image: url(../img/sprite.png);
  background-position: -387px -335px;
  width: 14px;
  height: 11px;
  top: 7px;
}

.contacts-item--time::before {
  background-image: url(../img/sprite.png);
  background-position: -353px -318px;
  width: 17px;
  height: 16px;
  top: 5px;
}

/* COMMON
 * -------------------------------------------------- */

body {
  min-width: 320px;
}

.wrapper .main {
  padding: 60px 0 80px 0;
  overflow: hidden;
}

.wrapper .article {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  padding-left: 27%;
}

.wrapper .article table {
  width: 100%;
  margin-bottom: 20px;
}

.wrapper .article table td,
.wrapper .article table th {
  border: 1px solid #cccccc;
  padding: 10px;
}

.wrapper .section {
  overflow: hidden;
}

.wrapper .section + .section {
  padding: 125px 0 0 0;
}

/* Pages */

/* INDEX
 * -------------------------------------------------- */

/* Hero */

.hero {
  height: calc(100vh - 116px);
  margin-bottom: 70px;
  position: relative;
}

.hero-text,
.hero-title {
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1;
  font-family: 'Open Sans', Arial, sans-serif;
}

.hero-title {
  font-size: 3.6rem;
  margin-bottom: 30px;
  font-weight: 300;
}

.hero-text {
  font-size: 4.8rem;
  font-weight: 700;
  letter-spacing: -1px;
}

.hero-text span {
  font-size: 3.65rem;
  font-weight: 300;
}

.hero::before {
  content: '';
  display: block;
  position: absolute;
  background: url('../img/hero-overlay.png') 50% 50% no-repeat;
  background-size: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.hero .slick-slider,
.hero .slick-list,
.hero .slick-track,
.hero .slick-slide,
.hero .carousel-item,
.hero .container {
  height: 100%;
}

.hero .container {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  width: 1095px;
  margin-left: -547.5px;
}

.hero .container::before {
  content: '';
  display: block;
  position: absolute;
  left: -10%;
  height: 106.2%;
  top: 3%;
  width: 100%;
  background: url('../img/hero-triangle.png') 0 0 no-repeat;
  background-size: auto 100%;
}

.hero .container .inner {
  position: absolute;
  z-index: 2;
  bottom: 50px;
  left: 23%;
  right: 20px;
}

.hero .carousel-item {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

/* About */

.about {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.about > * {
  min-width: 0;
}

.about-image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 38%;
      flex: 0 0 38%;
  max-width: 38%;
}

.about-article {
  -webkit-box-flex: 1;
  -ms-flex: 1;
      flex: 1;
  padding-left: 45px;
}

/* Build */

.tabs-build {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.tabs-build > * {
  min-width: 0;
}

.tabs-build .tab-aside {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 36%;
      flex: 0 0 36%;
  max-width: 36%;
  background: #f39c12;
  color: #ffffff;
  padding: 65px 15px 65px 50px;
}

.tabs-build .tab-aside-label {
  text-transform: uppercase;
  font-size: 3.5rem;
  line-height: 1;
  margin-bottom: 30px;
  font-weight: 700;
}

.tabs-build .tab-aside .tab-item {
  float: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tabs-build .tab-aside .tab-item > * {
  min-width: 0;
}

.tabs-build .tab-aside .tab-item.-active {
  font-weight: bold;
}

.tabs-build .tab-aside .tab-item .tab-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 85px;
      flex: 0 0 85px;
  max-width: 85px;
  text-align: center;
}

.tabs-build .tab-aside .tab-item .tab-icon .icon {
  margin: 0 auto;
}

.tabs-build .tab-aside .tab-item .tab-label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
      flex: 1;
  font-size: 1.6rem;
  line-height: 1.2;
  padding-left: 20px;
  font-family: 'PT Sans', Arial, sans-serif;
}

.tabs-build .tab-section {
  -webkit-box-flex: 1;
  -ms-flex: 1;
      flex: 1;
  position: relative;
}

.tabs-build .tab-section::after {
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  background: url('../img/triangle.png') 0 50% no-repeat;
  background-size: auto 100%;
  width: 1000%;
  margin-left: -33.5%;
}

.tabs-build .tab-section .tab-content {
  height: 100%;
}

.tabs-build .tab-section .tab-content .tab-item {
  height: 100%;
}

.tabs-build .tab-section .tab-image {
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
}

.tabs-build .tab-section .tab-article {
  padding: 55px 160px 0 95px;
  position: absolute;
  top: 50%;
  height: 50%;
  left: 0;
  width: 100%;
}

/* Services */

.tabs-services .tab-title,
.tabs-navigation .tab-title,
.tabs-contacts .tab-title {
  background: #86dbff;
  position: relative;
}

.tabs-services .tab-title::after,
.tabs-navigation .tab-title::after,
.tabs-contacts .tab-title::after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  left: 50%;
  width: 50%;
  background-color: #224471;
  top: 0;
}

.tabs-services .tab-title .container,
.tabs-navigation .tab-title .container,
.tabs-contacts .tab-title .container {
  position: relative;
  z-index: 1;
}

.tabs-services .tab-title .inner,
.tabs-navigation .tab-title .inner,
.tabs-contacts .tab-title .inner {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.tabs-services .tab-title .inner > *,
.tabs-navigation .tab-title .inner > *,
.tabs-contacts .tab-title .inner > * {
  min-width: 0;
}

.tabs-services .tab-title .tab-item,
.tabs-navigation .tab-title .tab-item,
.tabs-contacts .tab-title .tab-item {
  height: 203px;
  font-size: 1.3rem;
  color: #ffffff;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.tabs-services .tab-title .tab-item > *,
.tabs-navigation .tab-title .tab-item > *,
.tabs-contacts .tab-title .tab-item > * {
  min-width: 0;
}

.tabs-services .tab-title .tab-item.-active .tab-item-title,
.tabs-services .tab-title .tab-item:hover .tab-item-title,
.tabs-navigation .tab-title .tab-item.-active .tab-item-title,
.tabs-navigation .tab-title .tab-item:hover .tab-item-title,
.tabs-contacts .tab-title .tab-item.-active .tab-item-title,
.tabs-contacts .tab-title .tab-item:hover .tab-item-title {
  color: #ffd55a;
}

.tabs-services .tab-title .tab-item-title,
.tabs-navigation .tab-title .tab-item-title,
.tabs-contacts .tab-title .tab-item-title {
  min-height: 69px;
  font-family: 'PT Sans', Arial, sans-serif;
  font-weight: 700;
  padding: 0 15px 15px 15px;
  width: 100%;
  -webkit-transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
       -o-transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
          transition: color 0.25s, border-color 0.25s, background-color 0.25s, opacity 0.25s;
}

.tabs-services .tab-title .tab-item-icon,
.tabs-navigation .tab-title .tab-item-icon,
.tabs-contacts .tab-title .tab-item-icon {
  margin: 0 auto 15px auto;
  text-align: center;
}

.tabs-services .tab-content .tab-item,
.tabs-navigation .tab-content .tab-item,
.tabs-contacts .tab-content .tab-item {
  height: 530px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 60px;
}

.tabs-services .tab-content .tab-item h2,
.tabs-navigation .tab-content .tab-item h2,
.tabs-contacts .tab-content .tab-item h2 {
  font-weight: 300;
}

.tabs-services .tab-content .tab-item .inner,
.tabs-navigation .tab-content .tab-item .inner,
.tabs-contacts .tab-content .tab-item .inner {
  max-width: 440px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
}

.layout-tabs-1 .tab-title .inner {
  margin-top: 0;
  margin-left: 0;
}

.layout-tabs-1 .tab-title .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%;
  padding-left: 0;
  padding-top: 0;
}

.layout-tabs-2 .tab-title .inner {
  margin-top: 0;
  margin-left: 0;
}

.layout-tabs-2 .tab-title .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%;
  padding-left: 0;
  padding-top: 0;
}

.layout-tabs-3 .tab-title .inner {
  margin-top: 0;
  margin-left: 0;
}

.layout-tabs-3 .tab-title .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding-left: 0;
  padding-top: 0;
}

.layout-tabs-4 .tab-title .inner {
  margin-top: 0;
  margin-left: 0;
}

.layout-tabs-4 .tab-title .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
  padding-left: 0;
  padding-top: 0;
}

.layout-tabs-5 .tab-title .inner {
  margin-top: 0;
  margin-left: 0;
}

.layout-tabs-5 .tab-title .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
      flex: 0 0 20%;
  max-width: 20%;
  padding-left: 0;
  padding-top: 0;
}

.layout-tabs-6 .tab-title .inner {
  margin-top: 0;
  margin-left: 0;
}

.layout-tabs-6 .tab-title .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%;
  padding-left: 0;
  padding-top: 0;
}

.layout-tabs-7 .tab-title .inner {
  margin-top: 0;
  margin-left: 0;
}

.layout-tabs-7 .tab-title .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 14.28571%;
      flex: 0 0 14.28571%;
  max-width: 14.28571%;
  padding-left: 0;
  padding-top: 0;
}

.layout-tabs-8 .tab-title .inner {
  margin-top: 0;
  margin-left: 0;
}

.layout-tabs-8 .tab-title .inner > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12.5%;
      flex: 0 0 12.5%;
  max-width: 12.5%;
  padding-left: 0;
  padding-top: 0;
}

/* Objects */

.layout-objects {
  margin-top: 0;
  margin-left: 0;
}

.layout-objects > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%;
  padding-left: 0;
  padding-top: 0;
}

.object {
  height: 375px;
  color: #ffffff;
  font-size: 1.7rem;
  position: relative;
  overflow: hidden;
}

.object-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
}

.object-link {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 2;
  color: #ffffff !important;
}

.object-content {
  opacity: 0;
  padding: 40px 40px 0 40px;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 300;
}

.object-title {
  font-size: 2.6rem;
  color: inherit;
  margin-bottom: 30px;
  font-weight: 700;
}

.object-title span {
  font-size: 1.7rem;
  display: block;
  text-transform: none;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 300;
}

.object:hover .object-link {
  background: rgba(21, 101, 152, 0.85);
}

.object:hover .object-content {
  opacity: 1;
}

.object-secondary:hover .object-link {
  background: rgba(49, 56, 97, 0.85);
}

.object-secondary .object-title {
  font-weight: 100;
}

.cover {
  position: relative;
  overflow: hidden;
}

.cover-stage {
  width: 200%;
  overflow: hidden;
}

.cover-front {
  width: 50%;
  float: left;
}

.cover-back {
  width: 50%;
  float: left;
  background: #cccccc;
}

.cover.-active .cover-stage {
  -webkit-transition: -webkit-transform 0.5s ease;
          transition: -webkit-transform 0.5s ease;
       -o-transition:         transform 0.5s ease;
          transition:         transform 0.5s ease;
          transition:         transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* Brands */

.carousel-brands .slick-slide:nth-child(even) .brand {
  background-color: #ececec;
}

.brand {
  height: 330px;
  background-color: #e5e5e5;
  text-align: center;
}

.brand-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.brand-link > * {
  min-width: 0;
}

.brand-link img:nth-child(1) {
  display: block;
}

.brand-link img:nth-child(2) {
  display: none;
}

.brand-link:hover img:nth-child(1) {
  display: none;
}

.brand-link:hover img:nth-child(2) {
  display: block;
}

/* PAGES
 * -------------------------------------------------- */

.visual {
  height: 440px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.visual::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 200%;
  left: 0;
  top: -20%;
  background: url('../img/visual.png') 50% 0 no-repeat;
  background-size: 100% auto;
  z-index: 1;
}

/* CONTACTS
 * -------------------------------------------------- */

.tabs-contacts .tab-title .tab-item {
  height: 43px;
  cursor: default;
}

#map {
  height: 100%;
  width: 100%;
}

.contacts + .form {
  margin-top: 100px;
}

.form-contacts {
  border: 2px solid #d5d3d3;
  border-right: 0;
  max-width: 595px;
  padding: 40px 125px 30px 50px;
  position: relative;
}

.form-contacts h2 {
  font-size: 2.6rem;
  font-weight: 900;
  font-family: 'Roboto', Arial, sans-serif;
}

.form-contacts .form-group {
  position: relative;
  z-index: 1;
}

.form-contacts::after {
  content: '';
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  background: url('../img/triangle.png') 0 50% no-repeat;
  background-size: auto 100%;
  width: 1000%;
  margin-left: -29.1%;
}

/* BRANDS
 * -------------------------------------------------- */

.layout-brands {
  margin-top: 40px !important;
}

.layout-brands {
  margin-top: 0;
  margin-left: 0;
}

.layout-brands > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding-left: 0;
  padding-top: 0;
}

.layout-brands .list-item:nth-child(even) .brand {
  background-color: #ececec;
}

@media screen and (min-width: 900px) {
  .tabs-build .tab-aside .tab-item + .tab-item {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1800px) {
  .brand {
    height: 300px;
  }
}

@media screen and (max-width: 1740px) {
  .footer::before {
    background-position: -15% 0;
  }

  .footer::after {
    background-position: 115% 0;
  }
}

@media screen and (max-width: 1700px) {
  .brand {
    height: 280px;
  }
}

@media screen and (max-width: 1600px) {
  .object {
    height: 300px;
    font-size: 1.4rem;
  }

  .object-title {
    font-size: 1.8rem;
  }

  .brand {
    height: 260px;
  }
}

@media screen and (max-width: 1440px) {
  .footer::before {
    background-position: -30% 0;
  }

  .footer::after {
    background-position: 130% 0;
  }
}

@media screen and (max-width: 1350px) {
  .object {
    height: 250px;
  }

  .object-content {
    padding: 20px;
  }
}

@media screen and (max-width: 1300px) {
  .brand {
    height: 240px;
  }
}

@media screen and (max-width: 1250px) {
  .object-title span {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1240px) {
  .footer::before {
    background-position: -40% 0;
  }

  .footer::after {
    background-position: 140% 0;
  }
}

@media screen and (max-width: 1200px) {
  .object-title {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1140px) {
  .footer::before {
    background-position: -50% 0;
  }

  .footer::after {
    background-position: 150% 0;
  }
}

@media screen and (max-width: 1100px) {
  .tabs-build .tab-aside {
    padding: 40px 20px 40px 20px;
  }

  .tabs-build .tab-section::after {
    display: none;
  }

  .tabs-build .tab-section .tab-article {
    padding: 40px 40px 0 40px;
  }

  .layout-objects {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-objects > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-top: 0;
  }
}

@media screen and (max-width: 1095px) {
  .hero .container {
    width: 100%;
    left: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 1040px) {
  .footer::before {
    display: none;
  }

  .footer::after {
    display: none;
  }

  .navigation-primary .list-item .list-link {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    margin-top: 60px;
  }

  .wrapper .main {
    padding: 30px 0;
  }

  .wrapper .article {
    padding-left: 0;
  }

  .wrapper .section + .section {
    padding-top: 60px;
  }

  .hero {
    margin-bottom: 60px;
  }

  .tabs-services .tab-title::after,
  .tabs-navigation .tab-title::after,
  .tabs-contacts .tab-title::after {
    display: none;
  }

  .tabs-services .tab-title .container,
  .tabs-navigation .tab-title .container,
  .tabs-contacts .tab-title .container {
    padding-left: 0;
    padding-right: 0;
  }

  .tabs-services .tab-title .tab-item-title,
  .tabs-navigation .tab-title .tab-item-title,
  .tabs-contacts .tab-title .tab-item-title {
    text-align: center;
  }

  .layout-tabs-8 .tab-title .inner {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-tabs-8 .tab-title .inner > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%;
    padding-left: 0;
    padding-top: 0;
  }

  .visual {
    height: 300px;
  }
}

@media screen and (max-width: 980px) {
  .header-logotype {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 150px;
        flex: 0 0 150px;
    max-width: 150px;
  }

  .navigation-primary .list-item .list-link {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 930px) {
  .navigation-primary .list-item .list-link {
    font-size: 1.2rem;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
        -ms-transform: scale(0.75);
            transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
        -ms-transform-origin: 0;
            transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
        -ms-transform-origin: 100%;
            transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media screen and (max-width: 900px) {
  .tabs-build {
    display: block;
  }

  .tabs-build .tab-aside {
    width: auto;
    max-width: none;
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .tabs-build .tab-aside .tab-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .tabs-build .tab-aside .tab-title > * {
    min-width: 0;
  }

  .tabs-build .tab-aside .tab-title {
    margin-top: -20px;
    margin-left: -20px;
  }

  .tabs-build .tab-aside .tab-title > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 20px;
    padding-top: 20px;
  }

  .tabs-build .tab-aside .tab-title {
    margin-top: -20px;
    margin-left: -20px;
  }

  .tabs-build .tab-aside .tab-title > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 20px;
    padding-top: 20px;
  }

  .tabs-build .tab-aside .tab-item .tab-label {
    font-size: 1.4rem;
  }

  .tabs-build .tab-section .tab-content {
    margin-top: 20px;
  }

  .tabs-build .tab-section .tab-image {
    height: 300px;
    position: static;
  }

  .tabs-build .tab-section .tab-article {
    padding: 20px 0 0 0;
    position: static;
    top: 0;
    height: auto;
  }
}

@media screen and (max-width: 800px) {
  .header .phone {
    font-size: 1.4rem;
  }

  .layout-tabs-5 .tab-title .inner {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-tabs-5 .tab-title .inner > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding-left: 0;
    padding-top: 0;
  }
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */

  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media screen and (max-width: 750px) {
  .footer-logotype {
    display: none;
  }

  .footer .inner {
    margin-top: 0;
    margin-left: -40px;
  }

  .footer .inner > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 40px;
    padding-top: 0;
  }

  .header::after {
    display: none;
  }

  .navigation-toggler {
    display: block;
  }

  .navigation-primary {
    display: none;
    position: absolute;
    top: 98px;
    right: 20px;
    width: 280px;
    height: auto;
    background: #ffffff;
    z-index: 10;
  }

  .navigation-primary.-active {
    display: block;
  }

  .navigation-primary .list-item {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    text-align: left;
  }

  .navigation-primary .list-item .list-link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
        flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 20px;
    font-size: 1.8rem;
  }

  .navigation-primary .list-item .list-title {
    padding-left: 20px;
  }

  .navigation-primary .list-item .list-icon {
    margin: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65px;
        flex: 0 0 65px;
    max-width: 65px;
    text-align: center;
  }

  .hero {
    height: 400px;
  }

  .hero-title {
    font-size: 3rem;
  }

  .hero-text {
    font-size: 4rem;
  }

  .hero-text span {
    font-size: 3rem;
  }

  .hero .container::before {
    display: none;
  }

  .hero .container .inner {
    left: 20px;
  }

  .about-image {
    display: none;
  }

  .about-article {
    padding-left: 0;
  }

  .layout-brands {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-brands > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-top: 0;
  }

  .layout-brands .list-item .brand {
    background-color: #e5e5e5 !important;
  }

  .layout-brands .list-item:nth-child(4n+1) .brand {
    background-color: #ececec !important;
  }

  .layout-brands .list-item:nth-child(4n) .brand {
    background-color: #ececec !important;
  }
}

@media screen and (max-width: 650px) {
  .form-contacts {
    border: 2px solid #d5d3d3;
    padding: 40px;
    max-width: 100%;
  }

  .form-contacts::after {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .footer-copyright {
    text-align: center;
  }

  .footer-contacts {
    display: none;
  }

  .footer .inner {
    margin-top: 0;
    margin-left: 0;
  }

  .footer .inner > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-top: 0;
  }

  .tabs-build .tab-aside .tab-title {
    margin-top: -20px;
    margin-left: -20px;
  }

  .tabs-build .tab-aside .tab-title > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
    padding-left: 20px;
    padding-top: 20px;
  }

  .tabs-build .tab-section .tab-image {
    height: 200px;
  }

  .tabs-services .tab-title .tab-item,
  .tabs-navigation .tab-title .tab-item,
  .tabs-contacts .tab-title .tab-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
        flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    padding: 5px 20px !important;
  }

  .tabs-services .tab-title .tab-item-title,
  .tabs-navigation .tab-title .tab-item-title,
  .tabs-contacts .tab-title .tab-item-title {
    text-align: left;
    padding: 0 0 0 10px;
    min-height: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
        flex: 1;
  }

  .tabs-services .tab-title .tab-item-icon,
  .tabs-navigation .tab-title .tab-item-icon,
  .tabs-contacts .tab-title .tab-item-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100px;
        flex: 0 0 100px;
    max-width: 100px;
    margin: 0;
    text-align: center;
  }

  .tabs-services .tab-title .tab-item-icon .icon,
  .tabs-navigation .tab-title .tab-item-icon .icon,
  .tabs-contacts .tab-title .tab-item-icon .icon {
    -webkit-transform: scale(0.8);
        -ms-transform: scale(0.8);
            transform: scale(0.8);
  }

  .tabs-services .tab-content .tab-item,
  .tabs-navigation .tab-content .tab-item,
  .tabs-contacts .tab-content .tab-item {
    padding-top: 20px;
    padding-bottom: 20px;
    height: auto;
  }

  .layout-tabs-8 .tab-title .inner {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-tabs-8 .tab-title .inner > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-top: 0;
  }

  .layout-tabs-5 .tab-title .inner {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-tabs-5 .tab-title .inner > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%;
    padding-left: 0;
    padding-top: 0;
  }

  .layout-objects {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-objects > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-top: 0;
  }

  .object-link {
    background: rgba(21, 101, 152, 0.5);
  }

  .object-content {
    opacity: 1;
  }

  .object-secondary .object-link {
    background: rgba(49, 56, 97, 0.5);
  }
}

@media screen and (max-width: 500px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 3rem;
  }

  .hero {
    height: 350px;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-text {
    font-size: 3rem;
  }

  .hero-text span {
    font-size: 2rem;
  }

  .hero .container .inner {
    bottom: 20px;
  }

  .tabs-build .tab-aside-label {
    font-size: 3rem;
  }

  .layout-brands .brand {
    height: 200px;
  }
}

@media screen and (max-width: 450px) {
  .header-logotype {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 150px;
        flex: 0 0 150px;
    max-width: 150px;
  }

  .header .logotype img {
    width: 60%;
  }

  .header .phone {
    padding: 4px 0;
    display: block;
    white-space: nowrap;
  }

  .header .phone:before {
    display: none;
  }

  .form-row > .form-item {
    width: 100%;
  }

  .form-row > .form-item + div {
    margin-top: 20px;
  }

  .layout-tabs-8 .tab-title .inner {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-tabs-8 .tab-title .inner > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-top: 0;
  }

  .layout-tabs-5 .tab-title .inner {
    margin-top: 0;
    margin-left: 0;
  }

  .layout-tabs-5 .tab-title .inner > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-top: 0;
  }

  .visual {
    height: 200px;
  }

  .form-contacts {
    padding: 20px;
  }
}

@media screen and (max-width: 400px) {
  .layout-brands .brand {
    height: 120px;
    padding: 10px;
  }
}

@media screen and (max-height: 500px) {
  .hero-title {
    font-size: 2rem;
  }

  .hero-text {
    font-size: 3rem;
  }

  .hero-text span {
    font-size: 2rem;
  }

  .hero .container .inner {
    bottom: 20px;
  }
}
